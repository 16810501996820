
// @ts-nocheck


export const localeCodes =  [
  "en-gb",
  "en-au",
  "de-at",
  "nl-be",
  "fr-be",
  "en-ca",
  "fr-ca",
  "cs-cz",
  "da",
  "fr",
  "de",
  "en-ie",
  "it",
  "ja",
  "no",
  "pl",
  "pt",
  "es",
  "sv",
  "de-ch",
  "fr-ch",
  "it-ch",
  "nl",
  "en-us"
]

export const localeLoaders = {
  "en-gb": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/en-gb.ts", load: () => import("../config/i18n/translations/en-gb.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_gb_ts" */), cache: true }],
  "en-au": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/en-au.ts", load: () => import("../config/i18n/translations/en-au.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_au_ts" */), cache: true }],
  "de-at": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/de.ts", load: () => import("../config/i18n/translations/de.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_de_ts" */), cache: true },
{ key: "../config/i18n/translations/de-at.ts", load: () => import("../config/i18n/translations/de-at.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_de_at_ts" */), cache: true }],
  "nl-be": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/nl.ts", load: () => import("../config/i18n/translations/nl.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_nl_ts" */), cache: true },
{ key: "../config/i18n/translations/nl-be.ts", load: () => import("../config/i18n/translations/nl-be.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_nl_be_ts" */), cache: true }],
  "fr-be": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/fr.ts", load: () => import("../config/i18n/translations/fr.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_fr_ts" */), cache: true },
{ key: "../config/i18n/translations/fr-be.ts", load: () => import("../config/i18n/translations/fr-be.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_fr_be_ts" */), cache: true }],
  "en-ca": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/en-ca.ts", load: () => import("../config/i18n/translations/en-ca.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ca_ts" */), cache: true }],
  "fr-ca": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/fr.ts", load: () => import("../config/i18n/translations/fr.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_fr_ts" */), cache: true },
{ key: "../config/i18n/translations/fr-ca.ts", load: () => import("../config/i18n/translations/fr-ca.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_fr_ca_ts" */), cache: true }],
  "cs-cz": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/cs.ts", load: () => import("../config/i18n/translations/cs.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_cs_ts" */), cache: true },
{ key: "../config/i18n/translations/cs-cz.ts", load: () => import("../config/i18n/translations/cs-cz.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_cs_cz_ts" */), cache: true }],
  "da": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/da.ts", load: () => import("../config/i18n/translations/da.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_da_ts" */), cache: true }],
  "fr": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/fr.ts", load: () => import("../config/i18n/translations/fr.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_fr_ts" */), cache: true }],
  "de": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/de.ts", load: () => import("../config/i18n/translations/de.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_de_ts" */), cache: true }],
  "en-ie": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/en-ie.ts", load: () => import("../config/i18n/translations/en-ie.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ie_ts" */), cache: true }],
  "it": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/it.ts", load: () => import("../config/i18n/translations/it.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_it_ts" */), cache: true }],
  "ja": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/ja.ts", load: () => import("../config/i18n/translations/ja.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_ja_ts" */), cache: true }],
  "no": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/no.ts", load: () => import("../config/i18n/translations/no.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_no_ts" */), cache: true }],
  "pl": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/pl.ts", load: () => import("../config/i18n/translations/pl.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_pl_ts" */), cache: true }],
  "pt": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/pt.ts", load: () => import("../config/i18n/translations/pt.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_pt_ts" */), cache: true }],
  "es": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/es.ts", load: () => import("../config/i18n/translations/es.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_es_ts" */), cache: true }],
  "sv": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/sv.ts", load: () => import("../config/i18n/translations/sv.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_sv_ts" */), cache: true }],
  "de-ch": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/de.ts", load: () => import("../config/i18n/translations/de.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_de_ts" */), cache: true },
{ key: "../config/i18n/translations/de-ch.ts", load: () => import("../config/i18n/translations/de-ch.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_de_ch_ts" */), cache: true }],
  "fr-ch": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/fr.ts", load: () => import("../config/i18n/translations/fr.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_fr_ts" */), cache: true },
{ key: "../config/i18n/translations/fr-ch.ts", load: () => import("../config/i18n/translations/fr-ch.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_fr_ch_ts" */), cache: true }],
  "it-ch": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/it.ts", load: () => import("../config/i18n/translations/it.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_it_ts" */), cache: true },
{ key: "../config/i18n/translations/it-ch.ts", load: () => import("../config/i18n/translations/it-ch.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_it_ch_ts" */), cache: true }],
  "nl": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/nl.ts", load: () => import("../config/i18n/translations/nl.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_nl_ts" */), cache: true }],
  "en-us": [{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/en.ts", load: () => import("../config/i18n/translations/en.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_ts" */), cache: true },
{ key: "../config/i18n/translations/en-us.ts", load: () => import("../config/i18n/translations/en-us.ts" /* webpackChunkName: "locale__vercel_path0_apps_web_config_i18n_translations_en_us_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../config/i18n/i18n.config.ts?hash=6acf0ddd&config=1" /* webpackChunkName: "__config_i18n_i18n_config_ts_6acf0ddd" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./config/i18n/i18n.config.ts",
  "locales": [
    {
      "code": "en-gb",
      "iso": "en-gb",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/en.ts",
        "config/i18n/translations/en-gb.ts"
      ]
    },
    {
      "code": "en-au",
      "iso": "en-au",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/en.ts",
        "config/i18n/translations/en-au.ts"
      ]
    },
    {
      "code": "de-at",
      "iso": "de-at",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/de.ts",
        "config/i18n/translations/de-at.ts"
      ]
    },
    {
      "code": "nl-be",
      "iso": "nl-be",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/nl.ts",
        "config/i18n/translations/nl-be.ts"
      ]
    },
    {
      "code": "fr-be",
      "iso": "fr-be",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/fr.ts",
        "config/i18n/translations/fr-be.ts"
      ]
    },
    {
      "code": "en-ca",
      "iso": "en-ca",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/en.ts",
        "config/i18n/translations/en-ca.ts"
      ]
    },
    {
      "code": "fr-ca",
      "iso": "fr-ca",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/fr.ts",
        "config/i18n/translations/fr-ca.ts"
      ]
    },
    {
      "code": "cs-cz",
      "iso": "cs-cz",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/cs.ts",
        "config/i18n/translations/cs-cz.ts"
      ]
    },
    {
      "code": "da",
      "iso": "da",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/da.ts"
      ]
    },
    {
      "code": "fr",
      "iso": "fr",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/fr.ts"
      ]
    },
    {
      "code": "de",
      "iso": "de",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/de.ts"
      ]
    },
    {
      "code": "en-ie",
      "iso": "en-ie",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/en.ts",
        "config/i18n/translations/en-ie.ts"
      ]
    },
    {
      "code": "it",
      "iso": "it",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/it.ts"
      ]
    },
    {
      "code": "ja",
      "iso": "ja",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/ja.ts"
      ]
    },
    {
      "code": "no",
      "iso": "no",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/no.ts"
      ]
    },
    {
      "code": "pl",
      "iso": "pl",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/pl.ts"
      ]
    },
    {
      "code": "pt",
      "iso": "pt",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/pt.ts"
      ]
    },
    {
      "code": "es",
      "iso": "es",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/es.ts"
      ]
    },
    {
      "code": "sv",
      "iso": "sv",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/sv.ts"
      ]
    },
    {
      "code": "de-ch",
      "iso": "de-ch",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/de.ts",
        "config/i18n/translations/de-ch.ts"
      ]
    },
    {
      "code": "fr-ch",
      "iso": "fr-ch",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/fr.ts",
        "config/i18n/translations/fr-ch.ts"
      ]
    },
    {
      "code": "it-ch",
      "iso": "it-ch",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/it.ts",
        "config/i18n/translations/it-ch.ts"
      ]
    },
    {
      "code": "nl",
      "iso": "nl",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/nl.ts"
      ]
    },
    {
      "code": "en-us",
      "iso": "en-us",
      "files": [
        "config/i18n/translations/en.ts",
        "config/i18n/translations/en.ts",
        "config/i18n/translations/en-us.ts"
      ]
    }
  ],
  "defaultLocale": "en-gb",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "config/i18n/translations",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": [
    {
      "langDir": "/vercel/path0/node_modules/.pnpm/nuxt-zod-i18n@1.6.0_rollup@4.14.1/node_modules/nuxt-zod-i18n/dist/runtime/locales",
      "locales": []
    }
  ]
}

export const normalizedLocales = [
  {
    "code": "en-gb",
    "iso": "en-gb",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/en-gb.ts"
      }
    ]
  },
  {
    "code": "en-au",
    "iso": "en-au",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/en-au.ts"
      }
    ]
  },
  {
    "code": "de-at",
    "iso": "de-at",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/de.ts"
      },
      {
        "path": "config/i18n/translations/de-at.ts"
      }
    ]
  },
  {
    "code": "nl-be",
    "iso": "nl-be",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/nl.ts"
      },
      {
        "path": "config/i18n/translations/nl-be.ts"
      }
    ]
  },
  {
    "code": "fr-be",
    "iso": "fr-be",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/fr.ts"
      },
      {
        "path": "config/i18n/translations/fr-be.ts"
      }
    ]
  },
  {
    "code": "en-ca",
    "iso": "en-ca",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/en-ca.ts"
      }
    ]
  },
  {
    "code": "fr-ca",
    "iso": "fr-ca",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/fr.ts"
      },
      {
        "path": "config/i18n/translations/fr-ca.ts"
      }
    ]
  },
  {
    "code": "cs-cz",
    "iso": "cs-cz",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/cs.ts"
      },
      {
        "path": "config/i18n/translations/cs-cz.ts"
      }
    ]
  },
  {
    "code": "da",
    "iso": "da",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/da.ts"
      }
    ]
  },
  {
    "code": "fr",
    "iso": "fr",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/fr.ts"
      }
    ]
  },
  {
    "code": "de",
    "iso": "de",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/de.ts"
      }
    ]
  },
  {
    "code": "en-ie",
    "iso": "en-ie",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/en-ie.ts"
      }
    ]
  },
  {
    "code": "it",
    "iso": "it",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/it.ts"
      }
    ]
  },
  {
    "code": "ja",
    "iso": "ja",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/ja.ts"
      }
    ]
  },
  {
    "code": "no",
    "iso": "no",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/no.ts"
      }
    ]
  },
  {
    "code": "pl",
    "iso": "pl",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/pl.ts"
      }
    ]
  },
  {
    "code": "pt",
    "iso": "pt",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/pt.ts"
      }
    ]
  },
  {
    "code": "es",
    "iso": "es",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/es.ts"
      }
    ]
  },
  {
    "code": "sv",
    "iso": "sv",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/sv.ts"
      }
    ]
  },
  {
    "code": "de-ch",
    "iso": "de-ch",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/de.ts"
      },
      {
        "path": "config/i18n/translations/de-ch.ts"
      }
    ]
  },
  {
    "code": "fr-ch",
    "iso": "fr-ch",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/fr.ts"
      },
      {
        "path": "config/i18n/translations/fr-ch.ts"
      }
    ]
  },
  {
    "code": "it-ch",
    "iso": "it-ch",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/it.ts"
      },
      {
        "path": "config/i18n/translations/it-ch.ts"
      }
    ]
  },
  {
    "code": "nl",
    "iso": "nl",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/nl.ts"
      }
    ]
  },
  {
    "code": "en-us",
    "iso": "en-us",
    "files": [
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/en.ts"
      },
      {
        "path": "config/i18n/translations/en-us.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
